/*
Author: Nishant Singh
*/

import { Cart } from '~api/cart';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAttributesName } from '~global/helpers';
// import { InternalTracking } from '~global/sociolla.dm.lib';
// ...........Actions creator
export const CART_PROUDUCT_COUNT = 'CART_PROUDUCT_COUNT';
export const CART_DETAIL = 'CART_DETAIL';
export const CART_ADD = 'CART_ADD';
export const CART_REORDER = 'CART_REORDER';
export const CART_RESET = 'CART_RESET';

// ...........Device storage
// showAsyncStorageContentInDev()
const saveDataToStorage = async (key, data) => {
  return await AsyncStorage.setItem(key, JSON.stringify(data));
};

const getDataFromStorage = async (key) => {
  return await AsyncStorage.getItem(key);
};

// ..........actions
// export const addProductToCart = (payload) => {
// 	return async (dispatch) => {
// 		try {
// 			let res = await Cart.addProductToCartRequest(payload);
// 			if (res.status === 200) {
// 				dispatch(getCartProductCount())
// 				dispatch(getUserCart())
// 			}
// 		} catch (err) {
// 			console.error(err.message)
//
// 		}
// 	}
// }
export const addProductToCart = createAsyncThunk(
  CART_ADD,
  async (payload, thunkAPI) => {
    try {
      const res = await Cart.addProductToCartRequest(payload);

      if (res.status === 200) {
        // const resultData = res?.data?.data ? res.data.data : {};
        thunkAPI.dispatch(getCartProductCount());
        // InternalTracking.addToCart({ ...resultData, ...payload });
        // thunkAPI.dispatch(getUserCart())
      }
      return res;
    } catch (err) {
      console.error(err.message);
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const cartReorder = createAsyncThunk(
  CART_REORDER,
  async (payload, thunkAPI) => {
    try {
      const res = await Cart.cartReorderRequest(payload);
      if (res.status === 200) {
        thunkAPI.dispatch(getCartProductCount());
      }
    } catch (err) {
      console.error(err.message);
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const addProductToLocalStorage = (product) => {
  return async () => {
    try {
      const payload = {
        brand: product.brand.name,
        combination_name: getAttributesName(
          product.default_combination.attributes,
        ),
        combination_id: product.default_combination._id,
        price: product.minimun_price_after_discount,
        qty: 1,
        _id: product._id,
        id: product.id,
        raw: product,
      };
      let cart = await getDataFromStorage('cart');
      if (!cart) {
        await saveDataToStorage('cart', [payload]);
      } else {
        cart = JSON.parse(cart);
        cart.push(payload);
      }
    } catch (err) {
      console.error(err.message);
    }
  };
};

export const getUserCart = createAsyncThunk(CART_DETAIL, async () => {
  try {
    const res = await Cart.userCartRequest();
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {}
});

export const getCartProductCount = createAsyncThunk(
  CART_PROUDUCT_COUNT,
  async () => {
    try {
      const res = await Cart.cartProductCountRequest();
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (err) {
      console.error(err.message);
    }
  },
);

export const setProductCount = createAsyncThunk(
  CART_PROUDUCT_COUNT,
  async (count) => {
    return count;
  },
);

export const getSpecialPriceProducts = () => {
  return async () => {
    try {
      const res = await Cart.specialPriceProductsRequest();
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (err) {
      console.error(err.message);
    }
  };
};

export const getGwpProducts = () => {
  return async () => {
    try {
      const res = await Cart.gwpProductsRequest();
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (err) {
      console.error(err.message);
    }
  };
};

export const getPoints = (params) => {
  return async () => {
    try {
      const res = await Cart.pointsRequest(params);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (err) {
      console.error(err.message);
    }
  };
};

export const applyRedeemPoints = (payload) => {
  return async () => {
    try {
      const res = await Cart.applyRedeemPointsRequest(payload);
      if (res.status === 200) {
        return res.data.data;
      }
    } catch (err) {
      return err.response.data.data;
    }
  };
};

export const cancelRedeemPoints = (params) => {
  return async () => {
    try {
      const res = await Cart.cancelRedeemPointsRequest(params);
      if (res.status === 200) {
        return res.data.success;
      }
    } catch (err) {
      console.error(err.message);
    }
  };
};

export const getExtrabags = createAsyncThunk(
  'cart/extrabags',
  async () =>
    await Cart.getExtrabagsRequest({
      filter: { is_active_in_lulla: true },
      sort: '-created_at',
      limit: 3,
    }).then(({ data: { data } }) => data),
);

export const toggleExtrabag = createAsyncThunk(
  'cart/toggleExtrabag',
  async ({ selected, id }, { dispatch }) =>
    await Cart.toggleExtrabagRequest({
      action: selected ? 'apply' : 'remove',
      id,
    })
      .then(() => dispatch(getExtrabags()))
      .then(() => dispatch(getUserCart())),
);

export const changeAddress = createAsyncThunk(
  'cart/changeAddress',
  async ({ cartId, addressId }, { dispatch }) =>
    await Cart.updateCartAddress(cartId, addressId).then(() =>
      dispatch(getUserCart()),
    ),
);
